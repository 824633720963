import Link from 'next/link';
import React from 'react';

import { CarouselRow } from 'components/common/carousel';
import Container from 'components/common/container';
import ItemCard from 'components/common/item-card';
import { Heading2, HeadingWrapper, HeadingLink } from 'components/common/text';
import { useArticleCta } from 'utils/articles';
import { SanityArticlePreview } from 'utils/types';

import * as St from './styles';
import { ArticleGridProps } from './types';

export function ArticleGridItemCard({
  article,
  openInNewTab,
}: {
  article: SanityArticlePreview;
  openInNewTab?: boolean;
}) {
  const { category, title, slug, image, isVideo } = article;
  const { watchCta, readCta } = useArticleCta();

  return (
    <ItemCard
      title={title}
      subtitle={category?.name}
      image={image}
      href={`/articles/${slug?.current}`}
      cta={isVideo ? watchCta : readCta}
      sidenote={article.readingTime}
      variant={isVideo ? 'transparent' : 'default'}
      openInNewTab={openInNewTab}
    />
  );
}

export default function ArticleGrid({
  title,
  articles = [],
  titleLink = {},
  section,
  layout = 'grid',
}: ArticleGridProps): React.ReactElement {
  const { text, url } = titleLink;

  const header = (
    <HeadingWrapper noGutter>
      <Heading2 inline>{title}</Heading2>
      {text && url && (
        <Link href={url} passHref>
          <HeadingLink>{text}</HeadingLink>
        </Link>
      )}
    </HeadingWrapper>
  );

  switch (layout) {
    case 'slider':
      return (
        <St.ArticleGridSection id={section?.id} bg="light">
          <Container outset>{header}</Container>
          <Container noGutter noPadding>
            <CarouselRow
              scrollBarReveal
              arrowLayout="overlay"
              removeScrollbarGap
            >
              {articles?.map((article) => {
                return (
                  <St.StyledCarouselItem
                    size="article"
                    key={article._createdAt}
                  >
                    <ArticleGridItemCard article={article} />
                  </St.StyledCarouselItem>
                );
              })}
            </CarouselRow>
          </Container>
        </St.ArticleGridSection>
      );
    default:
      return (
        <St.ArticleGridSection id={section?.id} bg="light">
          <Container>
            {header}
            <St.Grid>
              {articles?.map((article) => {
                return (
                  <ArticleGridItemCard
                    article={article}
                    key={article._createdAt}
                  />
                );
              })}
            </St.Grid>
          </Container>
        </St.ArticleGridSection>
      );
  }
}
