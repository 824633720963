import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';
import { FC, useState } from 'react';

import Button from 'components/common/button';
import { CarouselItem } from 'components/common/carousel';
import { Tick } from 'components/common/icons';
import { AddedLineItem } from 'utils/commercetools/types';
import { trackAddToBasket, trackCarouselProductClick } from 'utils/gtm/events';
import { AddingState } from 'utils/types';

import ProductTile from '../product-tile';
import { AddToBasketSuccess } from '../product-tile/styles';

import { IProductTileCarouselItemProps } from './types';

export const ProductTileCarouselItem: FC<IProductTileCarouselItemProps> = ({
  enableQuickBuy,
  index,
  product,
  tileSize = 'large',
  trackingId,
  onAddToBasket,
  onOpenQuickbuyModal,
}) => {
  const [addingState, setAddingState] = useState(AddingState.idle);

  const onClick = () => {
    trackingId && trackCarouselProductClick(product.sproutlSku, trackingId);
  };

  const onTileAddToBasket = async () => {
    if (!product.selectedPartner || !onAddToBasket) return;

    try {
      setAddingState(AddingState.adding);

      const origin = trackingId || 'product-tile-carousel-item';
      const lineItems: AddedLineItem[] = [
        {
          name: product.productName,
          sku: product.sproutlSku,
          channel: {
            typeId: 'channel',
            id: product.selectedPartner.id,
            key: undefined,
          },
          quantity: product.quantityMultiplier || 1,
          price: {
            centAmount: product.selectedPartner.centAmount,
            fractionDigits: 2,
          },
          categorySlug: product.categorySlug,
        },
      ];

      onAddToBasket && (await onAddToBasket(lineItems, origin));
      trackAddToBasket(lineItems, origin);

      setAddingState(AddingState.added);
    } catch (e) {
      Sentry.captureException(e, { level: Severity.Fatal });
      setAddingState(AddingState.idle);
    }
  };

  return (
    <CarouselItem size={tileSize}>
      <ProductTile
        testId="product-carousel-item"
        {...{
          enableQuickBuy,
          product,
          index,
          onClick,
          onOpenQuickbuyModal,
        }}
      />
      {onAddToBasket &&
        (addingState === AddingState.added ? (
          <AddToBasketSuccess>
            <Tick /> {addingState}
          </AddToBasketSuccess>
        ) : (
          <Button
            onClick={onTileAddToBasket}
            disabled={addingState !== AddingState.idle}
            variant="small"
            colorScheme="white"
            loading={addingState === AddingState.adding}
            loadingText="Adding"
          >
            {addingState}
          </Button>
        ))}
    </CarouselItem>
  );
};
