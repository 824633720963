import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  MarginBottomOptions,
  getAttributesLabel,
} from 'components/common/attributes-label/helpers';
import { AttributesLabel } from 'components/common/attributes-label/styles';
import Button from 'components/common/button';
import { CarouselRow } from 'components/common/carousel';
import { useCookieAccepted } from 'components/common/cookie-banner/utils';
import { FlowXFlex } from 'components/common/flow';
import { Close, Compost } from 'components/common/icons';
import Image from 'components/common/image';
import ModalNotification from 'components/common/modal-notification';
import {
  HeadingText,
  HeadingWrapper,
  CloseButton,
} from 'components/common/modal-notification/styles';
import ResponsiveBox from 'components/common/responsive-box';
import SiteLink from 'components/common/site-link';
import { Settings } from 'contexts';
import { AddedLineItem } from 'utils/commercetools/types';
import formatPrice from 'utils/formatPrice';
import { GlobalEvents, trigger } from 'utils/global-events';

import { ProductTileCarouselItem } from '../product-tile-carousel-item';

import {
  ProductDetails,
  ProductImageWrapper,
  ProductDetailsPrice,
  ProductDetailsTitle,
  ProductWrapper,
  Wrapper,
  StyledContainer,
  CompostAdvert,
  AddonsWrapper,
} from './styles';
import { IAddToNotification, IProductAttributesRawConfig } from './types';

const AddToNotification = ({
  isOpen = false,
  productDetails = [],
  addons = [],
  cartId,
  persist = false,
  showCompostAdvert,
  onClose,
}: IAddToNotification) => {
  const { supplementaryContent, featureFlags } = useContext(Settings);
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const productCount = productDetails.length;
  const productAttributesRawConfig = useMemo(
    () =>
      productDetails.reduce((accConfigObj, { attributesRaw, sku }) => {
        // { sku: attributesRaw }
        if (attributesRaw && sku) {
          return {
            ...accConfigObj,
            [sku]: getAttributesLabel(attributesRaw),
          };
        }
        return accConfigObj;
      }, {} as IProductAttributesRawConfig),
    [productDetails],
  );

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (!persist) {
      setTimeout(closeModal, 4000);
    }
  }, [isOpen, persist, closeModal]);

  const hasCookieAccepted = useCookieAccepted();
  const hasAddons = !!addons.length;

  const compostCta = showCompostAdvert &&
    featureFlags?.compostCalculatorActive && (
      <CompostAdvert href="/basket">
        <Compost />
        <h3>Need Compost?</h3>
        <p>Calculated for you in your basket</p>
      </CompostAdvert>
    );

  const onAddToBasket = (lineItems: AddedLineItem[], origin: string) => {
    return fetch('/api/basket/add-to-basket', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cartId,
        lineItems,
        origin,
        refetchDelivery: true,
      }),
    }).then(() => {
      trigger(GlobalEvents.headerBasketRefetch);
    });
  };

  return (
    <ModalNotification
      isBespokeModal
      isOpen={isModalOpen}
      closeModal={closeModal}
    >
      <StyledContainer noGutter>
        <Wrapper
          cookieAccepted={hasCookieAccepted}
          isOpen={isModalOpen}
          isCentred={hasAddons}
          data-testid="addToNotification"
        >
          <HeadingWrapper>
            <HeadingText>Added to basket</HeadingText>

            <FlowXFlex alignItems="center">
              {hasAddons && (
                <SiteLink href="/basket">
                  <Button
                    as="div"
                    variant="small"
                    colorScheme="black"
                    data-testid="addToNotification-checkout"
                  >
                    Checkout
                  </Button>
                </SiteLink>
              )}

              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <CloseButton type="button" onClick={closeModal}>
                <Close height={16} width={16} />
              </CloseButton>
            </FlowXFlex>
          </HeadingWrapper>
          <ProductWrapper productCount={productCount}>
            {productDetails.map(
              ({ src, title, price, sku, quantity }, index) => (
                <ProductDetails
                  key={`${title}-${index}`}
                  productCount={productCount}
                >
                  <>
                    {src ? (
                      <ProductImageWrapper productCount={productCount}>
                        <ResponsiveBox size="small" aspectRatio={1} rounded>
                          <Image
                            loader="imagekit"
                            src={src}
                            aspectRatio={1}
                            sizes="150px"
                            alt={title}
                          />
                        </ResponsiveBox>
                      </ProductImageWrapper>
                    ) : null}
                    {productCount <= 2 && (
                      <div>
                        <ProductDetailsTitle>{title}</ProductDetailsTitle>
                        {sku && productAttributesRawConfig[sku] && (
                          <AttributesLabel
                            stack
                            marginBottom={MarginBottomOptions.Small}
                          >
                            {productAttributesRawConfig[sku]}
                          </AttributesLabel>
                        )}
                        <ProductDetailsPrice>
                          {formatPrice(price.centAmount * quantity)}
                        </ProductDetailsPrice>
                      </div>
                    )}
                  </>
                </ProductDetails>
              ),
            )}
          </ProductWrapper>

          {hasAddons ? (
            <AddonsWrapper>
              {supplementaryContent?.addonsHeading && (
                <HeadingWrapper>
                  <HeadingText>
                    {supplementaryContent.addonsHeading}
                  </HeadingText>
                </HeadingWrapper>
              )}

              <CarouselRow scrollBarReveal arrowLayout="overlay-tile">
                {addons.map((product, index) => (
                  <ProductTileCarouselItem
                    tileSize="medium"
                    key={product.sproutlSku}
                    onAddToBasket={onAddToBasket}
                    {...{ product, index, trackingId: 'pdp-addon' }}
                  />
                ))}
              </CarouselRow>

              <footer>{compostCta}</footer>
            </AddonsWrapper>
          ) : (
            <>
              {compostCta}

              <SiteLink href="/basket">
                <Button
                  as="div"
                  width="full"
                  variant="primary"
                  data-testid="addToNotification-checkout"
                >
                  Checkout
                </Button>
              </SiteLink>
            </>
          )}
        </Wrapper>
      </StyledContainer>
    </ModalNotification>
  );
};

export default AddToNotification;
