import styled from 'styled-components';

import { CarouselItem } from 'components/common/carousel';
import Section from 'components/common/section';
import { tablet, desktop } from 'utils/media';

export const ArticleGridSection = styled(Section)`
  & + & {
    margin-top: var(--space-xl-3xl);
    padding-top: var(--space-xl-3xl);
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: var(--space-s-l);
  grid-template-columns: 100%;

  ${tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${desktop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const StyledCarouselItem = styled(CarouselItem)`
  > * {
    flex-grow: 1;
  }
`;
