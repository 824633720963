import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';
import { useState } from 'react';

import { IProductMeta } from 'types/product';
// import { getActiveCart } from 'utils/commercetools/cart';
import {
  addLineItems,
  // getVariantAttributesRawBySku,
} from 'utils/commercetools/cart';
import { AddedLineItem } from 'utils/commercetools/types';
import { trackAddToBasket } from 'utils/gtm/events';

const useAddToBasket = (origin: string, callback?: Function) => {
  const [addToBasketError, setAddToBasketError] = useState<string | null>(null);
  const [isAddingToBasket, setIsAddingToBasket] = useState<boolean>(false);

  const postAddToBasket = async (
    products: {
      product: IProductMeta;
      quantity: number;
    }[],
  ) => {
    setAddToBasketError(null);
    setIsAddingToBasket(true);

    const lineItems: AddedLineItem[] = products
      .filter(({ product }) => !!product && !!product?.selectedPartner)
      .map(({ product, quantity }) => ({
        name: product.productName,
        sku: product.sproutlSku,
        channel: {
          typeId: 'channel',
          id: product.selectedPartner.id,
          key: undefined,
        },
        quantity,
        price: {
          centAmount: product.selectedPartner.centAmount,
          fractionDigits: 2,
        },
        categorySlug: product.categorySlug,
      }));

    if (!lineItems.length) {
      return;
    }

    try {
      await addLineItems(lineItems, origin);

      trackAddToBasket(lineItems, origin);

      // const activeCart = await getActiveCart();
      callback &&
        callback({
          updatedAt: new Date(),
          items: products.map(({ product, quantity }) => ({
            title: product.productName,
            src: product.images?.[0],
            price: {
              centAmount: product.selectedPartner.centAmount,
              fractionDigits: 2,
            },
            quantity,
            sku: product.sproutlSku,
            // TODO: re-add this when we unify add to basket approach
            // * updating this function will break everything else
            // attributesRaw: getVariantAttributesRawBySku({
            //   sku: product.sproutlSku,
            //   lineItems: activeCart.lineItems,
            // }),
          })),
        });
    } catch (error: any) {
      setAddToBasketError(error.message);
      Sentry.captureException(error, { level: Severity.Fatal });
    }

    setIsAddingToBasket(false);
  };

  return { addToBasketError, isAddingToBasket, postAddToBasket };
};

export default useAddToBasket;
