import { useContext } from 'react';

import {
  TSanityBlock,
  ISanityTextBlock,
  ISanityEmbed,
} from 'components/cms-blocks/render-blocks/types';
import { Settings } from 'contexts';
import { toPlainText } from 'framework/sanity/to-plain-text';

import { SanityArticlePreview } from './types';

const AVERAGE_WPM = 250;
export const getEstimatedReadingTime = (text: string) =>
  Math.ceil(text.split(/\s+/g).length / AVERAGE_WPM);

export const formatVideoDuration = (seconds: number) => {
  const duration = Math.round(seconds);
  if (duration < 60) {
    return `${duration}s`;
  } else {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}s`;
  }
};

export const getVideoPlayerFromBlocks = (
  headerBlocks?: TSanityBlock[] | null,
): ISanityEmbed | ISanityEmbed | undefined =>
  headerBlocks?.find(
    (item): item is ISanityEmbed => item._type === 'embedBlock',
  );

export const useArticleCta = () => {
  const {
    supplementaryContent: { watchCta = 'Watch', readCta = 'Read' } = {
      watchCta: 'Watch',
      readCta: 'Read',
    },
  } = useContext(Settings);

  return { watchCta, readCta };
};

/**
 * @example "5 min read" or "52s video" or "1:32 video"
 */
export const getArticleConsumptionString = ({
  headerBlocks,
  footerBlocks = [],
}: SanityArticlePreview) => {
  let consumptionString;

  const headerVideo = getVideoPlayerFromBlocks(headerBlocks);

  if (!!headerVideo) return '';

  // @TODO: This is not handling 'copyTree' cms block, which is sometimes used
  const combinedPlainText = toPlainText(
    [
      (footerBlocks || [])
        .filter((item): item is ISanityTextBlock => item._type === 'textBlock')
        .flatMap((item) => item.body),
    ]
      .flat()
      .filter(
        (item): item is Sanity.PortableTextBlock => item?._type === 'block',
      ),
  );
  const estimatedReadingMin = getEstimatedReadingTime(combinedPlainText);

  consumptionString = `${estimatedReadingMin} min read`;

  return consumptionString;
};

export const aggregateSanityArticlePreview = (
  article: SanityArticlePreview,
): SanityArticlePreview => {
  const newArticle = {
    ...article,
    readingTime: getArticleConsumptionString(article),
    isVideo: !!getVideoPlayerFromBlocks(article.headerBlocks),
  };

  delete newArticle.footerBlocks;
  delete newArticle.headerBlocks;

  return newArticle;
};
