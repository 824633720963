import styled, { css } from 'styled-components';

import { AttributesLabel } from 'components/common/attributes-label/styles';
import { ButtonEl } from 'components/common/button/styles';
import { CarouselRowContainer } from 'components/common/carousel/styles';
import Container from 'components/common/container';
import { cookieOffset } from 'components/common/cookie-banner/styles';
import { Flow } from 'components/common/flow';
import { HeadingText } from 'components/common/modal-notification/styles';
import SiteLink from 'components/common/site-link';
import {
  Title,
  Price,
  AddToBasketSuccess,
} from 'components/product/product-tile/styles';
import { mobile, tablet } from 'utils/media';
import theme from 'utils/theme';

import { IProductDetailsTitleProps } from './types';

interface IWrapperProps {
  isOpen: boolean;
  isCentred: boolean;
  cookieAccepted: boolean;
}

interface IProductWrapperProps {
  productCount: number;
}

export const StyledContainer = styled(Container)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

export const Wrapper = styled.div<IWrapperProps>`
  --add-to-padding: var(--space-l);
  --carousel-first-child: var(--add-to-padding);

  background-color: ${theme.colors.offWhite};
  border-top-left-radius: var(--space-xl);
  border-top-right-radius: var(--space-xl);
  bottom: 0;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  left: 0;
  right: 0;
  max-width: 100vw;
  padding: ${({ cookieAccepted }) =>
    `var(--add-to-padding) var(--add-to-padding) ${cookieOffset(
      cookieAccepted,
      'var(--add-to-padding)',
    )} var(--add-to-padding)`};
  position: fixed;
  transform: translateY(${({ isOpen }) => (isOpen ? 0 : '100%')});
  transition: all ${theme.transitionSpeeds.normal}s ease-out;
  will-change: transform;

  ${({ isCentred }) =>
    isCentred &&
    css`
      max-height: calc(100% - var(--site-gutter));
      overflow-y: auto;
    `}

  ${tablet} {
    border-radius: var(--add-to-padding);
    padding: var(--add-to-padding);
    width: 100%;

    ${({ isCentred, isOpen, cookieAccepted }) =>
      isCentred
        ? css`
            max-height: calc(100% - var(--space-s) * 2);
            max-width: 716px;
            bottom: auto;
            width: calc(100% - var(--site-gutter) * 2);
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;

            ${HeadingText} {
              font-size: var(--step-2);
            }

            ${!cookieAccepted &&
            css`
              transform: translateX(-50%);
              top: var(--space-s);
              max-height: calc(
                100% - ${theme.sizes.cookies.barTight}px - var(--space-s) * 2
              );
            `}
          `
        : css`
            position: absolute;
            transform: translateY(${isOpen ? 'var(--space-l)' : '-100%'});
            max-width: 375px;
            top: var(--space-2xs);
            right: var(--site-gutter);
            left: auto;
            bottom: auto;
          `}
  }
`;

export const ProductWrapper = styled.div<IProductWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--space-s);
  margin-top: var(--space-s);
  margin-bottom: var(--space-s);
`;

export const ProductDetails = styled.div<IProductWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-s);
  flex: 1;

  ${mobile} {
    ${AttributesLabel} {
      display: none;
    }
  }
`;

export const ProductImageWrapper = styled.div<IProductWrapperProps>`
  flex-grow: 1;
  flex-basis: 80px;
  aspect-ratio: 1;

  ${mobile} {
    flex-basis: 60px;
  }

  + * {
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: 50%;
  }
`;

export const ProductDetailsTitle = styled.div<IProductDetailsTitleProps>`
  font-family: ${theme.fonts.body};
  font-size: var(--step-0);
  font-weight: ${theme.weights.bold};
  margin-bottom: ${(props) =>
    !props.marginBottom ? 'var(--space-xs)' : `${props.marginBottom}px`};

  ${mobile} {
    font-size: var(--step--1);
  }
`;

export const ProductDetailsPrice = styled.div`
  color: ${theme.colors.darkGreen};
  font-weight: ${theme.weights.bold};
`;

export const CompostAdvert = styled(SiteLink)`
  color: inherit;
  display: block;
  margin-bottom: var(--space-xs);

  svg {
    float: left;
    margin-right: var(--space-xs);
  }

  h3,
  p {
    margin: 0;
  }

  h3 {
    color: ${theme.colors.irisPurple};
    font-size: var(--step-1);
  }
`;

export const AddonsWrapper = styled(Flow)`
  background: ${theme.colors.white};
  padding: var(--space-s) var(--add-to-padding) var(--add-to-padding);
  margin: 0 calc(-1 * var(--add-to-padding)) calc(-1 * var(--add-to-padding));
  border-bottom-right-radius: var(--add-to-padding);
  border-bottom-left-radius: var(--add-to-padding);

  ${CompostAdvert} {
    min-width: 280px;
    margin-bottom: 0;
  }

  ${AddToBasketSuccess} {
    padding: var(--space-3xs-2xs) 0;
    margin-top: auto;
  }

  ${CarouselRowContainer} {
    margin-right: calc(-1 * var(--add-to-padding));
    margin-left: calc(-1 * var(--add-to-padding));
    width: calc(100% + var(--add-to-padding) * 2);

    ${ButtonEl} {
      margin-top: auto;
      align-self: flex-start;
      padding-right: var(--space-s);
      padding-left: var(--space-s);
    }
  }

  ${Title} {
    margin-top: var(--space-xs);
  }

  ${Price} {
    margin-bottom: var(--space-2xs);
  }
`;
