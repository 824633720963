import styled, { css } from 'styled-components';

import { Flow } from 'components/common/flow/index';
import ResponsiveBox from 'components/common/responsive-box';
import { tablet } from 'utils/media';
import theme from 'utils/theme';

import { ButtonEl } from '../button/styles';

import { TItemCardVariants } from './types';

export const ItemCard = styled.figure<{
  variant?: TItemCardVariants;
  landscape: Boolean;
}>`
  --item-card-image-height: 270px;
  --radii: ${theme.radii.medium};

  position: relative;
  overflow: hidden;
  border-radius: var(--radii);

  ${tablet} {
    --item-card-image-height: 340px;

    ${({ landscape, variant }) =>
      landscape &&
      css`
        border-radius: ${theme.radii.default};

        a {
          display: grid;
          grid-template-columns: 1fr 1fr;
          min-height: 170px;
          align-items: center;
        }

        ${Content} {
          border-radius: 0;
          align-self: stretch;
          justify-content: center;
        }

        ${Sidenote} {
          margin-left: 0;
        }

        ${ButtonEl} {
          display: none;
        }

        ${Subtitle} {
          font-size: var(--step-0);
        }

        ${Header} {
          margin-bottom: var(--space-2xs);
        }

        ${Footer} {
          margin-top: 0;
        }

        ${variant === 'default' &&
        css`
          ${ImageWrapper} {
            position: absolute;
            top: 0;
            left: 0;
            right: 50%;
            bottom: 0;
          }
        `}
      `}
  }

  ${({ variant = 'default' }) => {
    switch (variant) {
      case 'default':
        return css`
          ${Subtitle} {
            color: ${theme.colors.pineGreen};
          }

          ${Sidenote} {
            color: ${theme.colors.darkGrey};
          }
        `;
      case 'transparent':
        return css`
          &:after {
            content: '';
            background-color: ${theme.colors.modalBackgroundLight};
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
          }

          ${ImageSpacer} {
            flex-basis: var(--item-card-image-height);
          }

          ${ImageWrapper} {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }

          ${Content} {
            background: none;
            color: ${theme.colors.white};
          }

          ${Sidenote} {
            background-color: ${theme.colors.darkGrey}50;
            padding: var(--space-4xs) var(--space-2xs);
            border-radius: 5px;
          }
        `;
    }
  }}
`;

export const Link = styled.a`
  display: flex;
  flex-direction: column;
  color: inherit;
  height: 100%;
`;

export const ImageWrapper = styled(ResponsiveBox)`
  flex-basis: var(--item-card-image-height);
  object-fit: cover;
`;

export const ImageSpacer = styled.div``;

export const Header = styled(Flow)`
  margin-bottom: var(--space-s);
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 var(--radii) var(--radii);
  background-color: ${theme.colors.vistaWhite};
  padding: var(--space-s-l);
  align-items: flex-start;
  z-index: 1;
`;

export const Subtitle = styled.div`
  font-family: ${theme.fonts.heading};
  font-size: var(--step--1);
  text-transform: uppercase;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-top: auto;
`;

export const Sidenote = styled.span`
  font-size: var(--step--1);
  margin-left: var(--space-2xs);
`;
