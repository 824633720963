import Link from 'next/link';
import React from 'react';

import Button from 'components/common/button';
import Image from 'components/common/image';
import { Heading5 } from 'components/common/text';
import { mobile } from 'utils/media';

import * as St from './styles';
import { IItemCardProps } from './types';

const ItemCard = ({
  href,
  title,
  subtitle,
  image,
  cta,
  sidenote,
  loader,
  variant = 'default',
  landscape = false,
  openInNewTab = false,
}: IItemCardProps) => {
  const ctaProps =
    variant === 'transparent'
      ? ({
          variant: 'small',
          colorScheme: 'whiteTransparent',
        } as const)
      : ({
          variant: 'small',
          colorScheme: 'beige',
        } as const);

  return (
    <St.ItemCard
      variant={variant}
      landscape={landscape}
      className={`item-card--${variant}`}
    >
      <Link href={href} passHref>
        <St.Link
          {...(openInNewTab ? { target: '_blank', rel: 'noreferrer' } : {})}
        >
          {image && (
            <St.ImageWrapper aspectRatio={0.8}>
              <Image
                src={image}
                aspectRatio={0.8}
                sizes={`${mobile} 325px, 430px`}
                alt=""
                objectFit="cover"
                loader={loader}
              />
            </St.ImageWrapper>
          )}
          <St.ImageSpacer></St.ImageSpacer>
          <St.Content>
            <St.Header as="figcaption" size="2xs">
              {subtitle && <St.Subtitle>{subtitle}</St.Subtitle>}
              <Heading5 as="h3">{title}</Heading5>
            </St.Header>
            <St.Footer>
              {cta && (
                <Button as="span" {...ctaProps}>
                  {cta}
                </Button>
              )}
              {sidenote && <St.Sidenote>{sidenote}</St.Sidenote>}
            </St.Footer>
          </St.Content>
        </St.Link>
      </Link>
    </St.ItemCard>
  );
};

export default ItemCard;
