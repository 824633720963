import styled from 'styled-components';

import theme from 'utils/theme';

interface MainProps {
  light?: boolean;
}

const Main = styled.main<MainProps>`
  background-color: ${(props) =>
    props.light ? theme.colors.white : undefined};
`;

export default Main;
